document.addEventListener("DOMContentLoaded", function(event) {
  var scroll = new SmoothScroll('a[href*="#"]');

  var filterProductsSelect = document.querySelector('.filter-products');

  var menuButton = document.querySelector('.js-menu-button');
  var menuBlock = document.querySelector('.js-menu-nav');

  menuButton.addEventListener('click', function(e) {
    e.preventDefault();

    menuBlock.classList.toggle('js-menu-open');
  });

  if(filterProductsSelect) {
    filterProductsSelect.addEventListener('change', function(e) {
      e.preventDefault();

      document.querySelectorAll(`.filterable`).forEach(product => {
        product.classList.add('hidden');
      });
      document.querySelectorAll(`.filterable.${e.currentTarget.value}`).forEach(item => {
        item.classList.remove('hidden');
      });
    });

    document.querySelector('.clear-products-filter').addEventListener('click', function(e) {
      e.preventDefault();
      document.querySelectorAll(`.filterable`).forEach(item => {
        item.classList.remove('hidden');
      });
    });
  }

  var filterLinks = document.querySelectorAll('.download-filters a');

  filterLinks.forEach((filter) => {
    filter.addEventListener('click', function(e) {
      e.preventDefault();

      filterLinks.forEach((item) => {
        item.classList.remove('current');
      });
      this.classList.add('current');

      document.querySelectorAll(`.${this.dataset.target}`).forEach((item) => {
        item.classList.add('visible');
      });

      document.querySelectorAll(`.downloads__item.${this.dataset.target}`).forEach((item) => {
        item.classList.remove('hidden');
        item.classList.add('visible');
      });
      document.querySelectorAll(`.downloads__item:not(.${this.dataset.target})`).forEach((item) => {
        item.classList.remove('visible');
        item.classList.add('hidden');
      });
    });
  });
});
